<template>
  <div id="app">
    <header-vue
      v-if="$route.fullPath != '/pdf' && $route.path != '/policy'"
    ></header-vue>
    <router-view />
    <footer-vue
      v-if="$route.fullPath != '/pdf' && $route.path != '/policy'"
    ></footer-vue>
    <!-- <img @click="toLogin" class="service" src="~@/assets/service.png" alt="" /> -->
  </div>
</template>
<script>
import headerVue from "@/components/header.vue";
import footerVue from "@/components/footer.vue";
// @ is an alias to /src

export default {
  components: {
    headerVue,
    footerVue,
  },
  methods: {
    toLogin() {
      window.location.href = window.global.webLink;
    },
  },
};
</script>

<style>
.service {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 10px;
  width: 60px;
  height: 60px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  min-height: 100vh;
  overflow-x: hidden;
}
</style>
