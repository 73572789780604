<template>
  <div class="content">
    <div class="content-box">
      <h5 class="content-box-breadcrumb">
        {{ $t("首页") }}/ {{ $t(pathTitle) }}
      </h5>
      <div class="content-box-main">
        <div class="content-box-main-left">
          <sidebarVue
            :hasNav="hasNav"
            :navType="navType"
            :now-path="nowPath"
          ></sidebarVue>
        </div>
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebarVue from "@/components/sidebar.vue";

export default {
  components: {
    sidebarVue,
  },
  props: {
    // 一级栏目名
    navType: {
      type: String,
      default: "",
    },
    // 是否存在侧边导航
    hasNav: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      nowPath: "",
      pathTitle: "",
    };
  },
  mounted() {
    this.nowPath = this.$route.fullPath;
    this.pathTitle = this.$route.meta.title;
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  &-box {
    width: 1200px;
    padding: 0 2rem;
    &-breadcrumb {
      font-size: 12px;
      margin-top: 1rem;
      margin-bottom: 2rem;
      color: #141e30;
      line-height: 180%;
      font-weight: 300;
    }
    &-main {
      display: flex;
      &-left {
        margin-right: 50px;
      }
    }
  }
}
</style>
