<template>
  <div class="home">
    <bannerVue
      top="3rem"
      bottom="3rem"
      titleType="h1"
      bgcolor="#F2F4F6"
      :title="$t('法律文件')"
      :desc="
        $t(
          '您在我们平台开立账户之前，应该阅读与您注册账户的MarketWe实体相关的法律文件。'
        )
      "
      title-width="500px"
      desc-width="500px"
      pic-url=""
    ></bannerVue>
    <tempVue navType="why" :hasNav="false">
      <template>
        <div class="main">
          <div class="topic1">
            <div class="topic1-left">
              <h5 class="topic1-left-h5">
                {{
                  $t(
                    "请注意，我们的法律文件根据您持有交易账户的MarketWe实体以及适用于您的规定而有所不同。适用于您帐户的法律文件将在您的申请表中明确说明，您应该在完成申请之前仔细阅读该文件。"
                  )
                }}<br />{{
                  $t("要了解有关适用于您的法规的更多信息，请单击以下相关链接：")
                }}
              </h5>
            </div>
          </div>
          <div class="table">
            <el-collapse>
              <el-collapse-item
                :title="`MarketWe Markets Limited (${$t('英国地址')}）`"
                name="1"
              >
                <div class="desc">
                  {{
                    $t(
                      "在您与MarketWe Markets Limited 开户的同时，这就已经表明您已阅读，理解并且同意以下文件中的条款"
                    )
                  }}
                  <ol>
                    <li>
                      {{
                        $t(
                          "条款与条件 – 本协议旨在约束您与MarketWe Markets Limited之间的关系。"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t(
                          "隐私政策 – 本政策规定阐述了您与MarketWe Markets Limited进行交易时，我们如何收集和管理您的个人信息。"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t(
                          "Cookie政策 – 本政策描述了我们在MarketWe上使用的Cookie的类型以及我们如何管理它们。您使用我们的网站即表示您同意本政策中规定的条款。"
                        )
                      }}
                    </li>
                    <li>{{ $t("模拟平台的条款和条件") }}</li>
                    <li>
                      {{
                        $t(
                          "风险披露通知 – 该本通知包含有关与我们产品相关的风险的重要信息。"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t(
                          "订单执行政策 – 该本政策阐明阐述了我们如何确保采取合理的步骤措施以为我们的客户获得获取最佳结果。"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t(
                          "利益冲突政策 – 该本政策规定阐述了我们如何处理在与我们建立业务关系期间可能发生的任何潜在或实际利益冲突。"
                        )
                      }}
                    </li>
                    <li>
                      {{ $t("投诉通知 – 此本通知说明阐释了我们的投诉程序。") }}
                    </li>
                    <li>
                      {{
                        $t(
                          "网站条款与条件 – 您使用我们的网站即表示您同意遵守这些条款。"
                        )
                      }}
                    </li>
                    <li>
                      {{
                        $t(
                          "我们受监管的办事处 – 我们其他持牌机构的法律文件见此处"
                        )
                      }}
                    </li>
                  </ol>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="topic1">
            <div class="topic1-left">
              <h5 class="topic1-left-h5">
                {{
                  $t(
                    "如果您对文档有任何疑问或需要其他语言的信息，请发送请求至MarketWeapp@gamil.com，我们将为您做出安排。"
                  )
                }}
              </h5>
            </div>
          </div>
        </div>
      </template>
    </tempVue>
  </div>
</template>

<script>
import bannerVue from "@/components/banner.vue";
import tempVue from "@/components/temp.vue";
import tipVue from "@/components/tip.vue";
import tp4Vue from "@/components/tp4.vue";

export default {
  components: {
    bannerVue,
    tempVue,
    tipVue,
    tp4Vue,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toPath(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  .main {
    .topic1 {
      display: flex;
      &-left {
        margin-right: 3rem;
        &-h2 {
          font-weight: 600;
          font-size: 40px;
          margin-bottom: 12px;
          color: #141e30;
        }
        &-h5 {
          color: #141e30;
          font-size: 18px;
          line-height: 180%;
          font-weight: 300;
        }
      }
      &-right {
        height: 435px;
        width: 413px;
      }
    }
    .table {
      margin: 20px 0;
      border: 1px solid #ebeef5;
      ol {
        list-style: square !important;

        padding-left: 20px !important;
      }
    }
    /deep/.el-collapse-item__header {
      font-size: 14px;
      color: #575c65;
      padding: 30px 24px;
    }
    .desc {
      font-size: 14px;
      color: #575c65;
      padding: 8px 24px;
    }
  }
}
</style>
