export default [
  {
    path: '/education/learn-forex',
    name: 'learn-forex',
    component: () => import('@/views/education/learn-forex.vue'),
    meta: { title: '学习外汇交易' }
  },
  {
    path: '/education/learn-forex/what-is-pip-value',
    name: 'what-is-pip-value',
    component: () => import('@/views/education/what-is-pip-value.vue'),
    meta: { title: '什么是点值？' }
  },
  {
    path: '/education/learn-forex/risk-management',
    name: 'risk-management',
    component: () => import('@/views/education/risk-management.vue'),
    meta: { title: '风险管理' }
  },
  {
    path: '/education/learn-forex/technical-analysis',
    name: 'technical-analysis',
    component: () => import('@/views/education/technical-analysis.vue'),
    meta: { title: '技术分析' }
  },
  {
    path: '/education/learn-forex/trading-other-instruments',
    name: 'trading-other-instruments',
    component: () => import('@/views/education/trading-other-instruments.vue'),
    meta: { title: '交易其他工具' }
  },
  {
    path: '/education/learn-forex/how-forex-works',
    name: 'how-forex-works',
    component: () => import('@/views/education/how-forex-works.vue'),
    meta: { title: '外汇交易如何操作？' }
  },
  {
    path: '/education/learn-forex/fundamental-analysis',
    name: 'fundamental-analysis',
    component: () => import('@/views/education/fundamental-analysis.vue'),
    meta: { title: '基本面分析' }
  },
  {
    path: '/education/learn-forex/10-trading-terms',
    name: '10-trading-terms',
    component: () => import('@/views/education/10-trading-terms.vue'),
    meta: { title: '您需要了解的10个交易术语' }
  },
  {
    path: '/education/learn-forex/trend-lines',
    name: 'trend-lines',
    component: () => import('@/views/education/trend-lines.vue'),
    meta: { title: '趋势线' }
  },
  {
    path: '/education/learn-forex/technical-indicators',
    name: 'technical-indicators',
    component: () => import('@/views/education/technical-indicators.vue'),
    meta: { title: '技术指标' }
  },
  {
    path: '/education/learn-forex/what-is-swap-in-forex',
    name: 'what-is-swap-in-forex',
    component: () => import('@/views/education/what-is-swap-in-forex.vue'),
    meta: { title: '外汇中的掉期是什么？' }
  },
  {
    path: '/education/learn-trade-cfds',
    name: 'learn-trade-cfds',
    component: () => import('@/views/education/learn-trade-cfds.vue'),
    meta: { title: '学习交易差价合约' }
  },
  {
    path: '/education/trading-guides',
    name: 'trading-guides',
    component: () => import('@/views/education/trading-guides.vue'),
    meta: { title: '交易指南' }
  },
  {
    path: '/education/11-tips-improving-performance-expert-advisor',
    name: '11-tips-improving-performance-expert-advisor',
    component: () => import('@/views/education/news/advisor.vue'),
    meta: { title: '提高专家顾问（EA）性能的11个技巧' }
  },
  {
    path: '/education/extreme-volatility',
    name: 'extreme-volatility',
    component: () => import('@/views/education/news/extreme-volatility.vue'),
    meta: { title: '高波动率市场环境中的生存法则' }
  },
  {
    path: '/education/why-spread',
    name: 'why-spread',
    component: () => import('@/views/education/news/why-spread.vue'),
    meta: { title: '为什么点差会扩大' }
  },
  {
    path: '/education/down-up',
    name: 'down-up',
    component: () => import('@/views/education/news/down-up.vue'),
    meta: { title: '涨停和跌停' }
  },
  {
    path: '/education/VIX',
    name: 'VIX',
    component: () => import('@/views/education/news/vix.vue'),
    meta: { title: '波动率指数（VIX）' }
  },
  {
    path: '/education/what-trader-type-are-you',
    name: 'what-trader-type-are-you',
    component: () => import('@/views/education/news/what-trader.vue'),
    meta: { title: '您属于哪种交易类型？' }
  },
  {
    path: '/education/how-trade-gold',
    name: 'how-trade-gold',
    component: () => import('@/views/education/news/how-trade-gold.vue'),
    meta: { title: '如何用非美货币交易黄金' }
  },

]
