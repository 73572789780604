import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import supportView from '../views/support.vue'
import contactView from '../views/contact.vue'
import legaltView from '../views/legal-documentation.vue'
import pdfView from '../views/pdf.vue'
import pdf1View from '../views/pdf1.vue'
import loginView from '../views/login.vue'
import registerView from '../views/register.vue'
import educationView from '../views/education.vue'
import whyRouter from './config/why'
import tradingRouter from './config/trading'
import platformsRouter from './config/platforms'
import marketRouter from './config/market'
import educationRouter from './config/education'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/support',
    name: 'support',
    component: supportView
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: contactView
  },
  {
    path: '/legal-documentation',
    name: 'legal-documentation',
    component: legaltView
  },
  {
    path: '/education',
    name: 'education',
    component: educationView
  },
  {
    path: '/login',
    name: 'login',
    component: loginView
  },
  {
    path: '/register',
    name: 'register',
    component: registerView
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: pdfView
  },
  {
    path: '/policy',
    name: 'policy',
    component: pdf1View
  },
  ...whyRouter,
  ...tradingRouter,
  ...platformsRouter,
  ...marketRouter,
  ...educationRouter
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // 如果有保存的位置，则使用它
    } else {
      return { x: 0, y: 0 }; // 否则回到顶部或根据需要设置其他位置
    }
  }
})


export default router
