<template>
  <div class="banner" :style="{ backgroundColor: bgcolor }">
    <div
      class="box"
      :style="{ paddingTop: `${top}`, paddingBottom: `${bottom}` }"
    >
      <div class="left">
        <h1
          v-if="titleType == 'h1'"
          class="title"
          :style="{ color: titlecolor, width: titleWidth }"
        >
          {{ $t(title) }}
        </h1>
        <h2
          v-if="titleType == 'h2'"
          :style="{ color: titlecolor, width: titleWidth }"
        >
          {{ $t(title) }}
        </h2>
        <h5 class="desc" :style="{ color: desccolor, width: descWidth }">
          {{ $t(desc) }}
        </h5>
      </div>
      <div class="right" :style="{ width: picWidth, height: picHeight }">
        <img v-if="picUrl" :src="picUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 距离顶部高度
    top: {
      type: String,
      default: "",
    },
    // 距离底部高度
    bottom: {
      type: String,
      default: "",
    },
    // 背景色
    bgcolor: {
      type: String,
      default: "",
    },
    // 标题字体色
    titlecolor: {
      type: String,
      default: "",
    },
    // 描述字体色
    desccolor: {
      type: String,
      default: "",
    },
    // 标题内容
    title: {
      type: String,
      default: "",
    },
    // 标题类型
    titleType: {
      type: String,
      default: "h1",
    },
    // 标题宽度
    titleWidth: {
      type: String,
      default: "",
    },
    // 描述文本
    desc: {
      type: String,
      default: "",
    },
    // 描述宽度
    descWidth: {
      type: String,
      default: "",
    },
    // 图片地址
    picUrl: {
      type: String,
      required: true,
    },
    // 图片宽度
    picWidth: {
      type: String,
      default: "",
    },
    // 图片高度
    picHeight: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  display: flex;
  justify-content: center;
  .box {
    width: 1200px;
    padding: 0 2rem;
    display: flex;
    .left {
      display: flex;
      padding-right: 3rem;
      flex-direction: column;
      justify-content: center;
      .title {
        font-weight: 600;
        font-size: 46px;
        margin-bottom: 16px;
      }
      h2 {
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 12px;
      }
      .desc {
        font-size: 18px;
        line-height: 180%;
        font-weight: 300;
      }
    }
    .right {
      text-align: left;
      img {
        height: 100%;
      }
    }
  }
}
</style>
