import Vue from 'vue'
import VueI18n from 'vue-i18n'

// language
import cnLocale from './zh-cn' // 简中
import twLocale from './zh-TW' // 繁中
import enLocale from './en' // 英文
import arLocale from './ar' // 英文
import deLocale from './de' // 英文
import esLocale from './es' // 英文
import frLocale from './fr' // 英文
import hiLocale from './hi' // 英文
import idLocale from './id' // 英文
import jpLocale from './ja-jp' // 英文
import itLocale from './it' // 英文
import koLocale from './ko' // 英文
import ruLocale from './ru' // 英文
import thLocale from './th' // 英文
// import trLocale from './tr' // 英文
import viLocale from './vi' // 英文
import ptLocale from './pt' // 英文


Vue.use(VueI18n)

const messages = {
  'zh-cn': { ...cnLocale },
  'zh-hk': { ...twLocale },
  'en': { ...enLocale },
  'ar': { ...arLocale },
  'de': { ...deLocale },
  'es': { ...esLocale },
  'fr': { ...frLocale },
  'hi': { ...hiLocale },
  'id': { ...idLocale },
  'ja-jp': { ...jpLocale },
  'it': { ...itLocale },
  'ko': { ...koLocale },
  'ru': { ...ruLocale },
  'th': { ...thLocale },
  // 'tr': { ...trLocale },
  'vi': { ...viLocale },
  'pt': { ...ptLocale },
}

function getLang() {
  const defaultLanguage = localStorage.getItem('lang')
  if (defaultLanguage) {
    return defaultLanguage
  } else {
    localStorage.setItem('lang', 'en')
    return 'en'
  }
}

const i18n = new VueI18n({
  // set locale
  locale: getLang(),
  // set locale messages
  messages
})

export default i18n
