<template>
  <div class="app-nav">
    <div class="top">
      {{ $t("杠杆交易为复杂的金融产品，存在迅速亏损的高风险。") }}
    </div>
    <div class="nav">
      <div class="nav-content">
        <div class="nav-left" @click="home">
          <div class="logo">
            <img class="logo-img" src="~@/assets/logo.png" alt="" />
          </div>
          <div class="web-name">MarketWe</div>
        </div>

        <div class="nav-list">
          <el-dropdown
            placement="bottom"
            class="nav-item"
            v-for="(item, index) in tabList"
            :key="index"
            @command="activeChange"
          >
            <span class="el-dropdown-link" @click="activeChange(item.path)">
              {{ $t(item.text)
              }}<i
                v-if="item.text != '白皮书'"
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="i.path"
                v-for="(i, v) in item.tabs"
                :key="v"
                >{{ $t(i.name) }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="btn1" @click="toLogin">{{ $t("交易中心") }}</div>
        <div class="nav-right">
          <div class="login" @click="toLogin">
            {{ $t("登录") }}
          </div>
          <div class="register" @click="toLogin">
            {{ $t("立即注册") }}
          </div>
          <div class="lang">
            <el-dropdown @command="langChoose">
              <span class="el-dropdown-link">
                {{ langText }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in langOptions"
                  :key="index"
                  :command="item"
                  >{{ $t(item.label) }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LANGUAGE_TYPE } from "@/config/global_data";
export default {
  data() {
    return {
      langText: "",
      langOptions: LANGUAGE_TYPE,
      tabList: [
        {
          id: 1,
          text: "为何选择",
          path: "/why-demo",
          tabs: [
            {
              name: "我们是谁",
              path: "/why-demo/about",
            },
            {
              name: "我们的奖项",
              path: "/why-demo/awards",
            },
            {
              name: "高级客户服务",
              path: "/why-demo/premium-clients",
            },
            {
              name: "活跃交易者（Active trader）平台",
              path: "/why-demo/active-trader-program",
            },
            {
              name: "法人机构识别编码（LEI)",
              path: "/why-demo/legal-entity-identifier",
            },
          ],
        },
        {
          id: 2,
          text: "市场交易",
          path: "/trading",
          tabs: [
            { name: "交易产品", path: "/trading/instruments" },
            { name: "点差、掉期和佣金", path: "/spreads-swaps-commissions" },
            { name: "交易时间", path: "/trading/trading-hours" },
            { name: "交易账户", path: "/trading/accounts" },
            { name: "入金和出金", path: "/trading/funding-withdrawals" },
            { name: "立即开始", path: "/trading/get-started" },
            { name: "市场假期", path: "/trading/trading-hours" },
          ],
        },
        {
          id: 3,
          text: "平台和工具",
          path: "/trading-platforms",
          tabs: [
            { name: "交易平台", path: "/trading-platforms/platforms" },
            { name: "社交平台交易", path: "/trading-platforms/social-trading" },
            {
              name: "capitalise-ai",
              path: "/trading-platforms/tools/capitalise-ai",
            },
            {
              name: "维护计划",
              path: "/trading-platforms/maintenance-schedule",
            },
          ],
        },
        {
          id: 4,
          text: "市场分析",
          path: "/market-analysis",
          tabs: [
            // { name: "市场新闻", path: "/market-analysis/market-news" },
            { name: "交易工具", path: "/trading-platforms/tools" },
            { name: "我们的分析师", path: "/market-analysis/analysts" },
          ],
        },
        { id: 5, text: "白皮书", path: "/pdf" },
      ],
    };
  },
  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang) {
      this.langOptions.forEach((item, index) => {
        if (item.value == lang) {
          this.langText = item.label;
        }
      });
    } else {
      this.langText = "简体中文";
      localStorage.setItem("lang", "zh-cn");
    }
  },
  methods: {
    toLogin() {
      window.location.href = window.global.webLink;
    },
    langChoose(v) {
      this.langText = v.label;
      localStorage.setItem("lang", v.value);
      location.reload();
    },
    activeChange(v) {
      if (v == this.$route.fullPath) {
        return;
      } else {
        this.$router.push(v);
      }
    },
    home() {
      if ("/" == this.$route.fullPath) {
        return;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-nav {
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  .top {
    background: #040f23;
    color: #fff;
    text-align: center;
    padding: 8px 0;
    box-sizing: border-box;
    font-size: 13px;
  }
  .nav {
    padding: 20px 0;
    box-sizing: border-box;
    width: 1400px;
    margin: 0 auto;
    &-content {
      display: flex;
      align-items: center;
      .nav-left {
        cursor: pointer;
        display: flex;
        align-items: center;
        .logo {
          height: 45px;
          width: 45px;
          margin-right: 10px;
          &-img {
            height: 100%;
            width: 100%;
          }
        }
        .web-name {
          margin-right: 50px;
          font-size: 32px;
        }
      }
      .nav-list {
        color: #606266;
        font-size: 1rem;
        flex: 1 1 0%;
        display: flex;
        align-items: center;
        justify-content: center;
        .nav-item {
          cursor: pointer;
          margin-right: 2rem;
          .el-dropdown-link {
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
      .btn1 {
        color: #fff;
        cursor: pointer;
        display: inline-block;
        padding: 0 12px;
        font-size: 16px;
        margin: 12px;
        border-radius: 3px;
        background-color: #1fae87;
        box-shadow: 0 0 0 -2px #fff, 0 0 0 -1px #1fae87;
        transition: box-shadow 0.3s;
        height: 35px;
        line-height: 35px;
        &:hover,
        &:focus {
          animation-iteration-count: infinite;
          box-shadow: 0 0 0 3px #fff, 0 0 0 6px #1fae87;
          animation: gelatine 0.5s 1;
          transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
        }
      }

      @keyframes gelatine {
        0%,
        100% {
          transform: scale(1, 1);
        }
        25% {
          transform: scale(0.9, 1.1);
        }
        50% {
          transform: scale(1.1, 0.9);
        }
        75% {
          transform: scale(0.95, 1.05);
        }
      }

      .nav-right {
        display: flex;
        align-items: center;
        color: #606266;
        .login {
          cursor: pointer;
          margin: 0 12px;
          background: #fff;
          border-color: #fff;
          color: #333;
          min-width: 35px;
        }
        .register {
          cursor: pointer;
          background: #5d3cef;
          color: #fff;
          padding: 0 12px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 4px;
          text-align: center;
          border: 1px solid transparent;
          font-size: 16px;
          margin-right: 2rem;
        }
        .lang {
          cursor: pointer;
          font-size: 14px;
          .more {
            margin-left: 5px;
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }
}
</style>
