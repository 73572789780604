<template>
  <div class="tips">
    <div class="tips-item" v-for="(item, index) in tips" :key="index">
      <img class="tips-item-img" :src="item.pic" alt="" />
      <h5 class="tips-item-title" v-if="item.title">{{ $t(item.title) }}</h5>
      <h5 class="tips-item-desc">{{ $t(item.desc) }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 列表
    tips: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
.tips {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  &-item {
    background: #ffffff;
    box-shadow: 0 3px 10px 9px rgba(0, 0, 0, 0.05);
    border-top: 2px solid #2038d3;
    padding: 2rem;
    &-img {
      width: 40px;
      height: 40px;
    }
    &-title {
      font-size: 24px;
      font-weight: 400;
      margin: 1rem 0;
    }
    &-desc {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 300;
    }
  }
}
</style>
