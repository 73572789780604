import axios from 'axios'
import { message } from 'element-ui'

let BASE_URL = window.global.baseUrl;
const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000 // request timeout
})
// request拦截器
instance.interceptors.request.use(
  config => {

    return config
  },
  error => {
    console.error(error) // for debug
    return Promise.reject(error)
  }
)

// respone拦截器
instance.interceptors.response.use(
  response => {
    // Toast.clear()
    const res = response.data
    console.log(res)
    // 携带response响应头版本信息
    // const { headers } = response
    // res['kv'] = headers.kv || ''
    // store.dispatch('app/setServiceTime', headers['service-time'])
    return res

  },
  error => {
    //退出登录删除tokn
    // window.localStorage.removeItem('user');
    // Toast(`${i18n.t('您还未登录，请先登录')}`)
    // router.push('/login')

    // Toast.clear()

    console.log(error.message)
    return error
  }
)

// GET请求
export const requestGet = (url, params) => instance.get(url, { params })
// DELETE请求
export const requestDelete = (url, params) => instance.delete(url, { params })
// POST请求
export const requestPost = (url, params) => instance.post(url, params)
// PUT请求
export const requestPut = (url, params) => instance.put(url, params)

export default instance
