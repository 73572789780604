<template>
  <div style="width: 100vw; height: 100vh">
    <iframe
      src="web.pdf"
      style="width: 100%; height: 100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
