export default [
  {
    path: '/trading',
    name: 'trading',
    component: () => import('@/views/trading/index.vue'),
    meta: { title: '在我们的平台交易' }
  },
  {
    path: '/trading/instruments',
    name: 'instruments',
    component: () => import('@/views/trading/instruments.vue'),
    meta: { title: '交易产品' }
  },
  {
    path: '/trading/instruments/trade-forex',
    name: 'trade-forex',
    component: () => import('@/views/trading/instruments/trade-forex.vue'),
    meta: { title: '外汇' }
  },
  {
    path: '/trading/instruments/index-cfds',
    name: 'index-cfds',
    component: () => import('@/views/trading/instruments/index-cfds.vue'),
    meta: { title: '指数差价合约' }
  },
  {
    path: '/trading/instruments/commodities',
    name: 'commodities',
    component: () => import('@/views/trading/instruments/commodities.vue'),
    meta: { title: '大宗商品' }
  },
  {
    path: '/trading/instruments/etfs',
    name: 'etfs',
    component: () => import('@/views/trading/instruments/etfs.vue'),
    meta: { title: 'ETF差价合约' }
  },
  {
    path: '/trading/instruments/share-cfds',
    name: 'share-cfds',
    component: () => import('@/views/trading/instruments/share-cfds.vue'),
    meta: { title: '股票差价合约' }
  },
  {
    path: '/trading/instruments/cryptocurrencies',
    name: 'cryptocurrencies',
    component: () => import('@/views/trading/instruments/cryptocurrencies.vue'),
    meta: { title: '加密货币' }
  },
  {
    path: '/trading/instruments/currency-index-cfds',
    name: 'currency-index-cfds',
    component: () => import('@/views/trading/instruments/currency-index-cfds.vue'),
    meta: { title: '货币指数差价合约（CFD）' }
  },
  {
    path: '/spreads-swaps-commissions',
    name: 'spreads',
    component: () => import('@/views/trading/spreads.vue'),
    meta: { title: '点差、掉期和佣金' }
  },
  {
    path: '/trading/trading-hours',
    name: 'hours',
    component: () => import('@/views/trading/hours.vue'),
    meta: { title: '交易时间' }
  },
  {
    path: '/trading/accounts',
    name: 'accounts',
    component: () => import('@/views/trading/accounts.vue'),
    meta: { title: '交易账户' }
  }
  ,
  {
    path: '/trading/funding-withdrawals',
    name: 'funding-withdrawals',
    component: () => import('@/views/trading/funding-withdrawals.vue'),
    meta: { title: '入金和出金' }
  }
  ,
  {
    path: '/trading/get-started',
    name: 'get-started',
    component: () => import('@/views/trading/get-started.vue'),
    meta: { title: '立即开始' }
  }
]
