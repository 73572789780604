import {
  requestGet,
  requestDelete,
  requestPost,
  requestPut
} from './axios'

export default {
  requestGet,
  requestDelete,
  requestPost,
  requestPut
}
