export default [
  {
    path: '/trading-platforms',
    name: 'trading-platforms',
    component: () => import('@/views/platforms/index.vue'),
    meta: { title: '交易平台和工具' }
  },
  {
    path: '/trading-platforms/social-trading',
    name: 'social-trading',
    component: () => import('@/views/platforms/social-trading.vue'),
    meta: { title: '社交平台交易' }
  },
  {
    path: '/trading-platforms/maintenance-schedule',
    name: 'maintenance-schedule',
    component: () => import('@/views/platforms/maintenance-schedule.vue'),
    meta: { title: '维护计划' }
  },
  {
    path: '/trading-platforms/platforms',
    name: 'platforms',
    component: () => import('@/views/platforms/platforms/platforms.vue'),
    meta: { title: '交易平台' }
  },
  {
    path: '/trading-platforms/platforms/metatrader4',
    name: 'metatrader4',
    component: () => import('@/views/platforms/platforms/metatrader4.vue'),
    meta: { title: 'MetaTrader 4' }
  },
  {
    path: '/trading-platforms/platforms/metatrader5',
    name: 'metatrader5',
    component: () => import('@/views/platforms/platforms/metatrader5.vue'),
    meta: { title: 'MetaTrader 5' }
  },
  {
    path: '/trading-platforms/platforms/ctrader',
    name: 'ctrader',
    component: () => import('@/views/platforms/platforms/ctrader.vue'),
    meta: { title: 'ctrader' }
  },
  {
    path: '/trading-platforms/tools',
    name: 'tools',
    component: () => import('@/views/platforms/tools/tools.vue'),
    meta: { title: '工具' }
  }
  ,
  {
    path: '/trading-platforms/tools/capitalise-ai',
    name: 'capitalise-ai',
    component: () => import('@/views/platforms/tools/capitalise-ai.vue'),
    meta: { title: 'Capitalise.ai' }
  }
  ,
  {
    path: '/trading-platforms/tools/smart-trader-tools',
    name: 'smart-trader-tools',
    component: () => import('@/views/platforms/tools/smart-trader-tools.vue'),
    meta: { title: 'Smart Trader工具' }
  }
  ,
  {
    path: '/trading-platforms/tools/ctrader-automate',
    name: 'ctrader-automate',
    component: () => import('@/views/platforms/tools/ctrader-automate.vue'),
    meta: { title: 'cTrader Automate' }
  }
  ,
  {
    path: '/trading-platforms/tools/autochartist',
    name: 'autochartist',
    component: () => import('@/views/platforms/tools/autochartist.vue'),
    meta: { title: '自动图表分析系统(Autochartist)' }
  }
  ,
  {
    path: '/trading-platforms/tools/api-trading',
    name: 'api-trading',
    component: () => import('@/views/platforms/tools/api-trading.vue'),
    meta: { title: 'API交易' }
  }
  ,
  {
    path: '/trading-platforms/tools/vps-hosting',
    name: 'vps-hosting',
    component: () => import('@/views/platforms/tools/vps-hosting.vue'),
    meta: { title: 'VPS托管' }
  }
]
