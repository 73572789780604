<template>
  <div class="sidebar">
    <div class="top" v-if="hasNav">
      <div
        v-for="(item, index) in navs[navType]"
        :key="index"
        :class="item.path == nowPath ? 'active' : ''"
      >
        <el-collapse
          v-model="activeNames"
          v-if="item.navs"
          @change="handleChange(item.path)"
        >
          <el-collapse-item
            :title="$t(item.name)"
            :name="item.name"
            @click="toPath(item.path)"
            :class="item.path == nowPath ? 'blue' : ''"
          >
            <div class="navs">
              <div
                @click="toPath(i.path)"
                class="navs-item"
                v-for="(i, v) in item.navs"
                :key="v"
                :class="i.path == nowPath ? 'blue' : ''"
              >
                {{ $t(i.name) }}
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="nav" v-else @click="toPath(item.path)">
          {{ $t(item.name) }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <p class="bottom-p">{{ $t("做好交易准备了吗？") }}</p>
      <h5 class="bottom-h5">
        {{
          $t("它快速且容易上手。通过我们简单的申请流程，几分钟之内即可申请。")
        }}
      </h5>
      <button class="bottom-btn" @click="toLogin">
        {{ $t("开立真实用户") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 当前路径
    nowPath: {
      type: String,
      default: "",
    },
    // 一级栏目名
    navType: {
      type: String,
      default: "why",
    },
    // 是否包含侧边导航
    hasNav: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeNames: [],
      navs: {
        why: [
          {
            name: "我们是谁",
            path: "/why-demo/about",
          },
          {
            name: "我们的奖项",
            path: "/why-demo/awards",
          },
          {
            name: "高级客户服务",
            path: "/why-demo/premium-clients",
          },
          {
            name: "活跃交易者（Active trader）平台",
            path: "/why-demo/active-trader-program",
          },
          {
            name: "法人机构识别编码（LEI)",
            path: "/why-demo/legal-entity-identifier",
          },
        ],
        trading: [
          {
            name: "可用工具",
            path: "/trading/instruments",
            navs: [
              {
                name: "外汇",
                path: "/trading/instruments/trade-forex",
              },
              {
                name: "指数差价合约",
                path: "/trading/instruments/index-cfds",
              },
              {
                name: "大宗商品",
                path: "/trading/instruments/commodities",
              },
              {
                name: "ETF差价合约",
                path: "/trading/instruments/etfs",
              },
              {
                name: "股票差价合约",
                path: "/trading/instruments/share-cfds",
              },

              {
                name: "加密货币",
                path: "/trading/instruments/cryptocurrencies",
              },
              {
                name: "货币指数差价合约(CFD)",
                path: "/trading/instruments/currency-index-cfds",
              },
            ],
          },
          {
            name: "点差、掉期和佣金",
            path: "/spreads-swaps-commissions",
          },
          { name: "交易时间", path: "/trading/trading-hours" },
          { name: "交易账户", path: "/trading/accounts" },
          { name: "入金和出金", path: "/trading/funding-withdrawals" },
          { name: "立即开始", path: "/trading/get-started" },
        ],
        platforms: [
          {
            name: "交易平台",
            path: "/trading-platforms/platforms",
            navs: [
              {
                name: "metatrader4",
                path: "/trading-platforms/platforms/metatrader4",
              },
              {
                name: "metatrader5",
                path: "/trading-platforms/platforms/metatrader5",
              },
              {
                name: "ctrader",
                path: "/trading-platforms/platforms/ctrader",
              },
            ],
          },
          {
            name: "工具",
            path: "/trading-platforms/tools",
            navs: [
              {
                name: "Capitalise.ai",
                path: "/trading-platforms/tools/capitalise-ai",
              },
              {
                name: "Smart Trader工具",
                path: "/trading-platforms/tools/smart-trader-tools",
              },
              {
                name: "cTrader Automate",
                path: "/trading-platforms/tools/ctrader-automate",
              },
              {
                name: "自动图表分析系统(Autochartist)",
                path: "/trading-platforms/tools/autochartist",
              },
              {
                name: "API交易",
                path: "/trading-platforms/tools/api-trading",
              },
              {
                name: "VPS托管",
                path: "/trading-platforms/tools/vps-hosting",
              },
            ],
          },
        ],
        education: [
          {
            name: "学习外汇交易",
            path: "/education/learn-forex",
            navs: [
              {
                name: "什么是点值？",
                path: "/education/learn-forex/what-is-pip-value",
              },
              {
                name: "风险管理",
                path: "/education/learn-forex/risk-management",
              },
              {
                name: "技术分析",
                path: "/education/learn-forex/technical-analysis",
              },
              {
                name: "交易其他工具",
                path: "/education/learn-forex/trading-other-instruments",
              },
              {
                name: "外汇交易如何操作？",
                path: "/education/learn-forex/how-forex-works",
              },

              {
                name: "基本面分析",
                path: "/education/learn-forex/fundamental-analysis",
              },
              {
                name: "您需要了解的10个交易术语",
                path: "/education/learn-forex/10-trading-terms",
              },
              {
                name: "趋势线",
                path: "/education/learn-forex/trend-lines",
              },

              {
                name: "技术指标",
                path: "/education/learn-forex/technical-indicators",
              },
              {
                name: "外汇中的掉期是什么？",
                path: "/education/learn-forex/what-is-swap-in-forex",
              },
            ],
          },
          {
            name: "学习交易差价合约",
            path: "/education/learn-trade-cfds",
          },
          { name: "交易指南", path: "/education/trading-guides" },
        ],
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.activeNames = [];
      if (this.nowPath.includes("/trading/instruments")) {
        this.activeNames.push("可用工具");
      } else if (this.nowPath.includes("/trading-platforms/platforms")) {
        this.activeNames.push("交易平台");
      } else if (this.nowPath.includes("/trading-platforms/tools")) {
        this.activeNames.push("工具");
      } else if (this.nowPath.includes("/education/learn-forex")) {
        this.activeNames.push("学习外汇交易");
      }
    });
  },
  methods: {
    toLogin() {
      window.location.href = window.global.webLink;
    },
    toPath(v) {
      if (this.nowPath == v) {
        return;
      } else {
        this.$router.push(v);
      }
    },
    handleChange(v) {
      if (this.$route.fullPath == v) {
        return;
      } else {
        this.$router.push(v);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 350px;
  .top {
    margin-bottom: 4rem;
    .nav {
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid #ebeef5;
      height: 48px;
      line-height: 48px;
      font-size: 13px;
    }
    .active {
      color: #5d3cef !important;
    }
    .navs {
      background: #f6f6f6;
      padding-left: 20px;
      padding-top: 20px;
      &-item {
        height: 50px;
        cursor: pointer;
      }
      .blue {
        color: #5d3cef !important;
      }
    }
    /deep/.el-collapse-item__header {
      color: unset;
    }
    /deep/.el-collapse {
      border-bottom: unset;
      border-top: unset;
    }
  }

  .bottom {
    border: 1px solid #d8dee1;
    padding: 24px;
    width: 352px;
    &-p {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    &-h5 {
      color: #141e30;
      font-size: 18px;
      line-height: 180%;
      font-weight: 300;
      margin-bottom: 1rem;
    }
    &-btn {
      background: #5d3cef;
      border: unset;
      border-radius: 64px;
      color: #fff;
      cursor: pointer;
      height: 66px;
      line-height: 66px;
      padding: 0 54px;
    }
  }
}
</style>
