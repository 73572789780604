export default [
  {
    path: '/market-analysis',
    name: 'market-analysis',
    component: () => import('@/views/market/index.vue'),
    meta: { title: '市场分析' }
  },
  {
    path: '/market-analysis/market-news',
    name: 'about',
    component: () => import('@/views/market/market-news.vue'),
    meta: { title: '市场新闻' }
  },
  {
    path: '/market-analysis/analysts',
    name: 'analysts',
    component: () => import('@/views/market/analysts.vue'),
    meta: { title: '我们的分析师' }
  },

]
