<template>
  <div class="content"></div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          title: "邮箱",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
}
</style>
