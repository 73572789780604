export default [
  {
    path: '/why-demo',
    name: 'home',
    component: () => import('@/views/why/index.vue'),
    meta: { title: '为何选择' }
  },
  {
    path: '/why-demo/about',
    name: 'about',
    component: () => import('@/views/why/about.vue'),
    meta: { title: '我们是谁' }
  },
  {
    path: '/why-demo/awards',
    name: 'awards',
    component: () => import('@/views/why/awards.vue'),
    meta: { title: '我们的奖项' }
  },
  {
    path: '/why-demo/premium-clients',
    name: 'premium-clients',
    component: () => import('@/views/why/premium.vue'),
    meta: { title: '我们的奖项' }
  },
  {
    path: '/why-demo/active-trader-program',
    name: 'active-trader-program',
    component: () => import('@/views/why/active.vue'),
    meta: { title: '活跃交易者（Active Trader）计划' }
  }
  ,
  {
    path: '/why-demo/legal-entity-identifier',
    name: 'legal-entity-identifier',
    component: () => import('@/views/why/legal-entity-identifier.vue'),
    meta: { title: '法人机构识别编码（LEI)' }
  }
]
