<template>
  <div class="content">
    <bannerVue
      bgcolor="#f2f4f6"
      titlecolor="#141e30"
      desccolor="#141e30"
      :title="$t('客户支持')"
      title-width="1200px"
      desc-width="1200px"
      :desc="
        $t(
          '从下载交易平台到管理您的交易风险，您将在此处找到您需要了解的所有信息。'
        )
      "
      picUrl=""
      top="3rem"
      bottom="3rem"
    ></bannerVue>
    <div class="topic1">
      <div class="topic1-box">
        <h5>{{ $t("首页") }}/ {{ $t("客户支持") }}</h5>
      </div>
    </div>
    <div class="topic5">
      <div class="topic5-box">
        <div class="topic5-box-content">
          <h1 class="topic5-box-content-h1">{{ $t("常见问题") }}</h1>
          <h5 class="topic5-box-content-h5">
            {{
              $t(
                "以下是我们的常见问题和答案。请访问我们的常见问题专区以了解更多热门问题，或在屏幕底端打开在线咨询与我们的专业团队实时沟通。"
              )
            }}
          </h5>
          <div class="table">
            <el-collapse>
              <el-collapse-item
                :title="$t('MarketWe 提供哪些账户类型?')"
                name="1"
              >
                <div class="desc">
                  <strong>{{
                    $t("我们提供两种账户类型以满足交易者的需求")
                  }}</strong>
                </div>
                <div class="desc">
                  <strong>{{ $t("MarketWe 账户") }}</strong>
                </div>
                <div class="desc">
                  {{
                    $t(
                      "我们的MarketWe账户包括原始点差，这意味着我们将从流动性提供商处收到的点差直接传递给我们的客户。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "与 MetaTrader 不同，当您在 cTrader上交易时，佣金率由您交易的货币而不是您账户的入金货币决定。cTrader MarketWe账户的佣金率是您交易的货币中第一个报价货币的七个单位 -例如，NZDCAD一手交易将需要支付7新西兰元的佣金。"
                    )
                  }}
                </div>
                <div class="desc">
                  <strong>{{ $t("标准账户") }}</strong>
                </div>
                <div class="desc">
                  {{
                    $t(
                      "我们的标准账户是免佣金的，包括我们的原始点差+1点增加（例如0点点差加1点= 1点[买入价和卖出价每个方向0.5点）。"
                    )
                  }}
                </div>
              </el-collapse-item>
              <el-collapse-item :title="$t('什么是掉期？')" name="2">
                <div class="desc">
                  {{
                    $t(
                      "外汇掉期利率是指在外汇交易中隔夜持有头寸的隔夜或展期利息（即赚取或支付的利息）。这些利率由银行间点差和交叉货币基础决定。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "掉期费用是双向的。这意味着交易者可以赚取掉期费用，或者他们也可能会在相关交易头寸上产生掉期费用。这将取决于交易的工具，您的头寸是多头还是空头，以及交易是否在展期期间持有。由于即期外汇合约以T+2为基础结算，因此在周三展期时将收取三倍掉期费用以计入周末利息。"
                    )
                  }}
                </div>
              </el-collapse-item>
              <el-collapse-item
                :title="$t('MarketWe 是 ECN/STP 交易者还是做市商？')"
                name="3"
              >
                <div class="desc">
                  {{
                    $t(
                      "我们经常被问及这一问题。回答这个问题的部分困难在于，ECN和STP是从上市市场制度中摘取的营销术语，并且行业中的不同提供商也对其给出了不同的含义。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "真正的ECN或STP经纪商是介绍经纪人或在白标计划下使用其他人的系统进行运作的经纪商。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "我们是我们提供的产品的发行人，我们可以控制我们使用的系统，因为我们倾向于让客户与我们建立直接关系。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "尽管如此，我们也可以被认为是“ECN”经纪商，因为我们没有运行任何专有交易账户。相比之下，我们通过电子通信网络从外部流动性提供商（定价不是由我们或与我们相关的任何人提供）处获得定价，并且这种定价会传递给您和我们的其他客户，而无需交易台进行干预。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "对每笔交易进行对冲会增加交易第二分支出现问题的可能性，从而也会影响第一分支。因此，我们不会与外部流动性提供商对冲每一笔头寸，因为我们希望通过为您带来价格确定性和速度来为您提供更好的产品。请放心，我们非常重视风险管理实践，并密切监控我们的风险，以确保我们不会遭受过高的风险。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "我们认为“做市商”是指经营交易台并创建自己价格的人，定价可能会基于他们自己的专有交易头寸或风险敞口。我们目前不以这种方式运营，因此虽然我们在AFS许可证上拥有做市授权（与英国其他所有许可产品发行人一样），但我们认为自己不是做市商。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{ $t("有关于此的更多信息，请联系") }}
                  <span class="blue">MarketWeapp@gamil.com</span> 。
                </div>
              </el-collapse-item>
              <el-collapse-item :title="$t('贵平台有哪些交易条件？')" name="4">
                <div class="desc">
                  {{
                    $t("我们的目标是为各种金融工具提供低交易成本和快速执行。")
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "您可以通过“与我们交易”一栏了解我们的交易条件，其中介绍了我们提供的金融工具、掉期、点差和佣金、交易账户等。"
                    )
                  }}
                </div>
              </el-collapse-item>
              <el-collapse-item :title="$t('我如何开立账户？')" name="5">
                <div class="desc">
                  {{
                    $t(
                      "如需开立真实账户，请前往我们的主页并选择“真实账户 > 开立真实账户。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "该过程大约需要5-10分钟即可在线完成。系统将提示您完成加入测验并提供您的开户身份证明文件，这些文件可以在申请期间在线上传。"
                    )
                  }}
                </div>
                <div class="desc">
                  {{
                    $t(
                      "然后我们的后台团队将审核您的申请。一旦我们验证了您的文件，我们就会向您发送您的交易账户登录信息。"
                    )
                  }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="topic4">
      <h2 class="topic4-h2">{{ $t("做好交易准备了吗？") }}</h2>
      <h5 class="topic4-h5">
        {{
          $t(
            "只需少量入金便可随时开始交易。我们简单的申请流程仅需几分钟便可完成申请."
          )
        }}
      </h5>
      <div class="topic4-btn">{{ $t("开立真实用户") }}</div>
    </div>
  </div>
</template>

<script>
import bannerVue from "@/components/banner.vue";

export default {
  components: {
    bannerVue,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content {
  .top-2 {
    margin-top: 2rem;
  }
  .blue {
    color: #00e;
    text-decoration: underline;
  }
  .topic1 {
    width: 100%;
    display: flex;
    justify-content: center;
    &-box {
      width: 1200px;
      padding-left: 2rem;
      padding-right: 2rem;
      h5 {
        font-size: 12px;
        margin-top: 1rem;
        margin-bottom: 2rem;
        color: #141e30;
        line-height: 180%;
        font-weight: 300;
      }
    }
  }
  .topic4 {
    background: #18268f;
    height: 394px;
    width: 100%;
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-h2 {
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 12px;
    }
    &-h5 {
      font-size: 18px;
      line-height: 180%;
      font-weight: 300;
    }
    &-btn {
      background: #2465f1;
      border: unset;
      border-radius: 64px;
      color: #fff;
      cursor: pointer;
      padding: 0 40px;
      height: 46px;
      line-height: 46px;
      box-sizing: border-box;
      margin: 1rem 0;
      span {
        display: inline-flex;
        align-items: center;
      }
    }
  }
  .topic5 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    &-box {
      width: 1200px;
      justify-content: center;
      padding-left: 2rem;
      padding-right: 2rem;
      display: flex;
      &-content {
        width: 768px;
        &-h1 {
          text-align: center;
          margin-top: 2rem;
          font-weight: 600;
          font-size: 46px;
          margin-bottom: 16px;
          color: #141e30;
        }
        &-h5 {
          text-align: center;
          margin-bottom: 2rem;
          color: #141e30;
          font-size: 18px;
          line-height: 180%;
          font-weight: 300;
        }
        .table {
          border: 1px solid #ebeef5;
        }
        /deep/.el-collapse-item__header {
          font-size: 14px;
          color: #575c65;
          padding: 30px 24px;
        }
        .desc {
          font-size: 14px;
          color: #575c65;
          padding: 8px 24px;
        }
      }
    }
  }
}
</style>
