<template>
  <div class="footer">
    <div class="footer-content">
      <div class="web-info">
        <img class="logo" src="~@/assets/logo.png" alt="" />
        <h2 class="name">MarketWe</h2>
      </div>
      <div class="navs-list">
        <div class="navs">
          <div
            class="nav"
            :class="index == 0 || index == 6 ? 'active' : ''"
            v-for="(item, index) in list1"
            :key="index"
            @click="topath(item)"
          >
            <a
              class="email"
              href="mailto:whistleblow@marketwe.com"
              v-if="item.path == 'none'"
            >
              {{ $t(item.name) }}</a
            >
            <template v-else>
              {{ $t(item.name) }}
            </template>
          </div>
        </div>
        <div class="navs">
          <div
            class="nav"
            @click="topath(item)"
            :class="index == 0 ? 'active' : ''"
            v-for="(item, index) in list2"
            :key="index"
          >
            {{ $t(item.name) }}
          </div>
        </div>
        <div class="navs">
          <div
            class="nav"
            @click="topath(item)"
            :class="index == 0 ? 'active' : ''"
            v-for="(item, index) in list3"
            :key="index"
          >
            {{ $t(item.name) }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer-desc">
      <div class="policys">
        <div
          class="policy"
          @click="topath(item)"
          v-for="(item, index) in list4"
          :key="index"
        >
          {{ $t(item.name) }}
        </div>
      </div>
      <div class="tips">
        {{
          $t(
            "风险警告：差价合约（CFD）是复杂的工具，由于杠杆作用，存在快速亏损的高风险。85.12% 的散户投资者在与该提供商进行差价合约交易时账户亏损。您应该考虑自己是否了解差价合约的原理，以及是否有承受资金损失的高风险的能力。"
          )
        }}
      </div>
      <div class="tips">
        {{
          $t(
            "您不拥有标的所有权或权力。过去的表现并不代表未来的表现，税法也可能会发生变化。本网站上的信息本质上是一般性信息，没有考虑您或您客户的个人目标，财务状况或需求。请在做任何交易决定之前阅读我们的RDN和其他法律文件，并确保您完全了解风险。我们鼓励您寻求独立的建议。"
          )
        }}
      </div>
      <div class="tips">
        {{
          $t(
            "如果本网站上的信息以及所提供的产品和服务违反任何国家当地法律法规，本网站上的信息以及所提供的产品和服务均无意分发给这些国家或地区的任何人。"
          )
        }}
      </div>
      <div class="tips">
        {{ $t("证书地址") }}：14th - 15th Floors, MarketWe Tower, 110
        Bishopsgate, City of London, London EC2N 4AY , England
      </div>
      <div class="tips">{{ $t("电话") }}：+44 7802338655</div>
    </div>
    <el-image
      ref="elImage"
      style="width: 0; height: 0"
      :src="bigImageUrl"
      :preview-src-list="logicImageList"
    >
    </el-image>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list1: [
        { name: "MARKETWE INVESTMENT CO.,LTD", path: "/" },
        { name: "为什么选择MarketWe", path: "/why-demo" },
        { name: "在我们的平台进行交易", path: "/trading" },
        { name: "平台", path: "/trading-platforms/" },
        { name: "交易工具", path: "/trading-platforms/tools" },
        { name: "支持", path: "/support" },
        { name: "联系我们", path: "/contact-us" },
        { name: "MarketWeapp@gamil.com", path: "none" },
      ],
      list2: [
        { name: "市场分析", path: "/market-analysis" },
        { name: "市场新闻", path: "/market-analysis/market-news" },
        { name: "认识我们的分析师", path: "/market-analysis/analysts" },
      ],
      list3: [
        { name: "教育", path: "/education" },
        { name: "学习交易外汇", path: "/education/learn-forex" },
        { name: "学习交易差价合约", path: "/education/learn-trade-cfds" },
        { name: "交易指南", path: "/education/trading-guides" },
      ],
      list4: [
        { name: "合法文件", path: "/legal-documentation" },
        { name: "隐私政策", path: "/privacy.html" },
        { name: "网站条款和条件", path: "/copy.html" },
        { name: "Cookie 政策", path: "/cookie.html" },
        { name: "举报人政策", path: "/pocy.html" },
        { name: "注册证书", path: "none", pic: [require("@/assets/msb.png")] },
        {
          name: "关于我们",
          path: "none",
          pic: [
            require("@/assets/4.png"),
            require("@/assets/3.png"),
            require("@/assets/5.png"),
          ],
        },
      ],
      bigImageUrl: "",
      logicImageList: [],
    };
  },
  methods: {
    topath(item) {
      if (this.$route.fullPath == item.path || item.path == "none") {
        if (item.pic) {
          item.pic.forEach((i, index) => {
            this.logicImageList.push(i);
          });
          this.$nextTick(() => {
            this.$refs.elImage.clickHandler();
          });
        } else if (item.name == "MarketWeapp@gamil.com") {
          return;
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // 使滚动平滑
          });
          return;
        }
      } else {
        if (item.path.includes("html")) {
          this.$router.push("/policy?url=" + item.path);
        } else {
          this.$router.push(item.path);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #000;
  color: #fff;
  padding: 2rem 0;

  box-sizing: border-box;
  &-content {
    border-bottom: 1px solid #fff;
    display: flex;
    width: 1200px;
    padding: 0 2rem;
    box-sizing: border-box;
    margin: 0 auto 2rem;
    .web-info {
      display: flex;
      // align-items: center;
      width: 300px;
      .logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      .name {
        font-size: 28px;
      }
    }
    .navs-list {
      display: grid;
      width: 1000px;
      column-gap: 1rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      .navs {
        .nav {
          cursor: pointer;

          font-size: 0.825rem;
          line-height: 1.25rem;
          height: 3rem;
          .email {
            color: #fff;
            text-decoration: dashed;
          }
          .email:hover {
            color: #5d3cef;
          }
        }
        .nav:hover {
          color: #5d3cef;
        }
        .active {
          font-size: 0.875rem;
          font-weight: 600;
        }
      }
    }
  }
  &-desc {
    width: 1200px;
    margin: 0 auto;
    .policys {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      .policy {
        cursor: pointer;
        margin-right: 3rem;
        font-size: 0.875rem;
      }
      .policy:hover {
        color: #5d3cef;
      }
    }
    .tips {
      color: #5c5c5c;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-top: 1.25rem;
    }
    .tips:first-child {
      margin-top: 0;
    }
    .tips:last-child {
      margin-top: 0.5rem;
    }
  }
}
</style>
