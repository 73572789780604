<template>
  <div class="topic4">
    <h2 class="topic4-h2">{{ $t("做好交易准备了吗？") }}</h2>
    <h5 class="topic4-h5">
      {{ $t("它快速且容易上手。通过我们简单的申请流程，几分钟之内即可申请。") }}
    </h5>
    <div class="topic4-btn" @click="toLogin">{{ $t("开立真实用户") }}</div>
  </div>
</template>

<script>
export default {
  methods: {
    toLogin() {
      window.location.href = window.global.webLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.topic4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  &-h2 {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 12px;
    color: #141e30;
  }
  &-h5 {
    color: #141e30;
    font-size: 18px;
    line-height: 180%;
    font-weight: 300;
  }
  &-btn {
    display: inline-block;
    background: #5d3cef;
    border-color: #5d3cef;
    border-radius: 65px;
    color: #fff;
    cursor: pointer;
    padding: 0 40px;
    height: 46px;
    line-height: 46px;
    margin: 1rem 0;
  }
}
</style>
