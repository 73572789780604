<template>
  <div class="home">
    <div class="topic1">
      <div class="topic1-left">
        <h2>
          {{ $t("通过") }}<span>MarketWe</span
          >{{ $t("平台交易外汇、商品、指数、股票和更多热门金融产品") }}
        </h2>
        <p>
          {{
            $t(
              "您可以探索全球最热门的金融市场。MarketWe提供0佣金、极具优势的低价差，以及灵活的杠杆配置。"
            )
          }}
        </p>
        <p>
          {{ $t("尝试") }} <span @click="toLogin">{{ $t("模拟账户") }}</span>
          {{ $t("或") }}
        </p>
        <button class="btn" @click="toLogin">{{ $t("开通真实账户") }}</button>
      </div>
      <div class="topic1-right">
        <img class="img" src="~@/assets/home/name-0218e326.png" alt="" />
      </div>
    </div>
    <div class="topic2">
      <div class="topic2-left">
        <div class="tabs">
          <div
            class="tab"
            :class="index == tabCur ? 'active' : ''"
            @click="tabchange(index)"
            v-for="(item, index) in tabs"
            :key="index"
          >
            {{ $t(item.name) }}
          </div>
        </div>
        <el-input
          class="inp"
          v-model="searchText"
          :placeholder="$t('搜寻您想知道的产品')"
          @input="search"
        ></el-input>
        <div class="titles">
          <div class="title1">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('名称')"
              placement="bottom"
            >
              <div style="display: inline">{{ $t("名称") }}</div>
            </el-tooltip>
          </div>
          <div class="title2">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('价格')"
              placement="bottom"
            >
              <div style="display: inline">{{ $t("价格") }}</div>
            </el-tooltip>
          </div>
          <div class="title3">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('成交量')"
              placement="bottom"
            >
              <div style="display: inline">{{ $t("成交量") }}</div>
            </el-tooltip>
          </div>
          <div class="title4">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('涨跌幅')"
              placement="bottom"
            >
              <div style="display: inline">{{ $t("涨跌幅") }}</div>
            </el-tooltip>
          </div>
        </div>
        <div
          class="currency"
          v-for="(item, index) in currencyList.slice(0, 5)"
          :key="index"
        >
          <div class="name">
            <img class="currency-logo" :src="item.to_logo" alt="" />
            <div>{{ item.symbol }}</div>
          </div>
          <div class="item1">{{ item.price }}</div>
          <div class="item2">{{ item.volume }}</div>
          <div class="item3">{{ item.rate }}</div>
          <div class="item4">
            <div class="btn" @click="toLogin">{{ $t("交易") }}</div>
          </div>
        </div>
      </div>
      <div class="topic2-right">
        <h2 class="title">{{ $t("更友好的交易") }}</h2>
        <div class="item">
          <img class="right" src="~@/assets/home/yes.png" alt="" />
          <div>{{ $t("简单直观界面，轻松操作") }}</div>
        </div>
        <div class="item">
          <img class="right" src="~@/assets/home/yes.png" alt="" />
          <div>{{ $t("支持网页、手机APP和桌面端，交易尽在指尖") }}</div>
        </div>
        <div class="item">
          <img class="right" src="~@/assets/home/yes.png" alt="" />
          <div>{{ $t("止损/追踪止损等风险管理工具") }}</div>
        </div>
        <div class="item">
          <img class="right" src="~@/assets/home/yes.png" alt="" />
          <div>{{ $t("多种技术图表和财经日历、实时新闻") }}</div>
        </div>
        <div class="item">
          <img class="right" src="~@/assets/home/yes.png" alt="" />
          <div>{{ $t("实时邮件、短信和推送通知") }}</div>
        </div>
        <div class="item">
          <img class="right" src="~@/assets/home/yes.png" alt="" />
          <div>{{ $t("不断改进，实现更好交易体验") }}</div>
        </div>
        <div class="b">
          <div class="btn" @click="toLogin">{{ $t("探索我们的平台") }}</div>
        </div>
      </div>
    </div>
    <div class="topic3">
      <h2 class="title">{{ $t("随时随地，自由交易") }}</h2>
      <p>{{ $t("支持网页、手机APP和桌面端，交易尽在指尖") }}</p>
      <div class="b">
        <div class="btn" @click="toLogin">{{ $t("使用WebTrader交易") }}</div>
      </div>
      <div class="down">
        <div class="down-left">
          <div class="down-left-up">
            <img class="down-left-up-img" src="~@/assets/logo.png" alt="" />
            <div class="tent">
              <div class="title1">{{ $t("扫码下载") }}</div>
              <div class="type">iOS & Android</div>
            </div>
          </div>
          <div class="down-left-down">
            <div class="title2">
              <div class="t1">{{ $t("桌面端") }}</div>
              <div class="t2">MacOS</div>
              <div class="t3">Windows</div>
            </div>
            <div class="img">
              <img
                class="img-pic"
                src="~@/assets/home/name-762ac6e9.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="down-right">
          <div class="title2">
            <div class="t1">{{ $t("移动端") }}</div>
            <div class="t2">App Store</div>
            <div class="t3">Google Play</div>
          </div>
          <div class="img">
            <img
              class="img-pic"
              src="~@/assets/home/name-2e290ea5.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="topic4">
      <h2 class="title3">{{ $t("值得信赖的国际平台") }}</h2>
      <p>{{ $t("我们致力于为客户提供安全负责的交易环境。") }}</p>
      <div class="boxs">
        <div class="box" v-for="(item, index) in boxs" :key="index">
          <div class="box-img">
            <img :src="item.pic" alt="" />
          </div>
          <div class="box-title">{{ $t(item.title) }}</div>
          <div class="box-desc">{{ $t(item.desc) }}</div>
        </div>
      </div>
      <h2 class="title4">{{ $t("更多服务助力您") }}</h2>
      <div class="boxs2">
        <div class="boxs2-box" v-for="(item, index) in boxs1" :key="index">
          <div class="boxs2-box-img">
            <img :src="item.pic" alt="" />
          </div>
          <div class="boxs2-box-title">{{ $t(item.title) }}</div>
          <div class="boxs2-box-desc">{{ $t(item.desc) }}</div>
        </div>
      </div>
    </div>
    <div class="topic5">
      <div class="topic5-box">
        <div class="topic5-box-left">
          <div class="topic5-box-left-title">
            {{ $t("简单三步，数分钟内即可开通账户") }}
          </div>
          <div style="height: 300px">
            <el-steps direction="vertical" :active="3">
              <el-step
                :title="$t('注册')"
                :description="$t('填写信息并提交您的申请')"
              ></el-step>
              <el-step
                :title="$t('入金')"
                :description="$t('通过多种方式可快速存入资金')"
              ></el-step>
              <el-step
                :title="$t('开始交易')"
                :description="$t('发掘交易机会及快速下单')"
              ></el-step>
            </el-steps>
          </div>
          <div class="topic5-box-left-b">
            <div class="topic5-box-left-b-btn" @click="toLogin">
              {{ $t("开通真实账户") }}
            </div>
          </div>
        </div>
        <div class="topic5-box-right">
          <img
            class="topic5-box-right-img"
            src="~@/assets/home/name-b7eb85d6.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="topic6">
      <h2 class="topic6-title">{{ $t("我们的成绩") }}</h2>
      <p>
        {{ $t("我们一向追求卓越，精益求精，致力为客户提供优质的交易服务。") }}
      </p>
      <p>
        {{
          $t(
            "MarketWe品牌非常荣幸获得了行业内著名的机构所颁发的殊荣，肯定了团队的一直努力及对客户的承担。"
          )
        }}
      </p>
      <div class="topic6-down">
        <div class="topic6-down-left">
          <div class="topic6-down-left-title">2,400,000+</div>
          <div class="topic6-down-left-desc">{{ $t("全球新增用户") }}</div>
        </div>
        <div class="topic6-down-right">
          <div class="topic6-down-right-item">
            <div class="topic6-down-right-item-name">
              {{ $t("最佳新闻及分析资讯提供者") }}
            </div>
            <div class="topic6-down-right-item-desc">FxDailyInfo</div>
          </div>
          <div class="topic6-down-right-item">
            <div class="topic6-down-right-item-name">
              {{ $t("最佳移动应用程式") }}
            </div>
            <div class="topic6-down-right-item-desc">Forex Awards</div>
          </div>
          <div class="topic6-down-right-item">
            <div class="topic6-down-right-item-name">
              {{ $t("亚洲最佳外汇劵商") }}
            </div>
            <div class="topic6-down-right-item-desc">Forex Awards</div>
          </div>
          <div class="topic6-down-right-item">
            <div class="topic6-down-right-item-name">
              {{ $t("英国增长最快劵商") }}
            </div>
            <div class="topic6-down-right-item-desc">
              International Business Magazine
            </div>
          </div>
          <div class="topic6-down-right-item">
            <div class="topic6-down-right-item-name">
              {{ $t("英国外汇客户满意大奖") }}
            </div>
            <div class="topic6-down-right-item-desc">
              Global Banking and Finance
            </div>
          </div>
          <img
            class="topic6-down-right-img"
            src="~@/assets/home/pic8.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="topic7">
      <h2 class="topic7-title">{{ $t("实时全球金融资讯") }}</h2>
      <div class="topic7-down">
        <div class="topic7-down-left">
          <h3 class="topic7-down-left-title">{{ $t("实时新闻") }}</h3>
          <div class="news" v-for="(item, index) in news" :key="index">
            <div class="news-content">{{ $t(item.content) }}</div>
            <div class="news-time">{{ item.time }}</div>
          </div>
        </div>
        <div class="topic7-down-right">
          <img
            class="topic7-down-right-img"
            src="~@/assets/home/pic9.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="topic8">
      <h2 class="topic8-title">{{ $t("合作伙伴") }}</h2>
      <div class="friends">
        <img
          class="friends-img"
          :src="item.pic"
          alt=""
          v-for="(item, index) in logo"
          :key="index"
        />
      </div>
      <h2 class="topic8-title1">{{ $t("出入金便捷") }}</h2>
      <p>{{ $t("多种常用支付渠道，支持快速出入金") }}</p>
      <img class="pay" src="~@/assets/home/pay.png" alt="" />
    </div>
    <div class="topic9">
      <h2 class="topic9-title">{{ $t("欢迎体验MarketWe服务") }}</h2>
      <div class="topic9-b">
        <div class="topic9-b-btn" @click="toLogin">
          {{ $t("立即开户交易") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      tabCur: 0,
      tabs: [
        { name: "外汇", type: "FX" },
        { name: "数字货币", type: "Crypto" },
        { name: "指数", type: "Indexes" },
        { name: "美股", type: "US stocks" },
        { name: "港股", type: "HK stocks" },
      ],
      searchText: "",
      currencyList: [],
      boxs: [
        {
          pic: require("@/assets/home/pic1.png"),
          title: "权威机构监管",
          desc: "行业权威监管及授权，赢得全球客户的信赖",
        },
        {
          pic: require("@/assets/home/pic2.png"),
          title: "保护资金安全",
          desc: "零售客户存款依照监管法规要求按需单独存放在信托账户",
        },
        {
          pic: require("@/assets/home/pic3.png"),
          title: "负数余额保护",
          desc: "为您提供账户负数余额保护，负数余额及时清零，让您的损失不超过入金额，安心交易",
        },
        {
          pic: require("@/assets/home/pic4.png"),
          title: "5*24小时客服",
          desc: "真诚专业的客户服务团队，24小时在线支持，乐意解决您的任何问题",
        },
      ],
      boxs1: [
        {
          pic: require("@/assets/home/pic5.png"),
          title: "交易策略",
          desc: "实时交易策略助您了解最新市场走势，更好把握交易时机。",
        },
        {
          pic: require("@/assets/home/pic6.png"),
          title: "交易知识",
          desc: "与MarketWe一起，免费学习交易知识，提升您的交易技能。",
        },
        {
          pic: require("@/assets/home/pic7.png"),
          title: "风险管理",
          desc: "了解MarketWe提供的免费风险管理工具，更好的保护您的资产。",
        },
      ],
      news: [
        {
          content:
            "【6部影片定档2025五一档】3月20日讯，今日《水饺皇后》《独一无二》两部影片官宣定档五一档上映，据猫眼专业版上映日历显示，截至当前共有6部影片定档2025年五一档上映，分别为《水饺皇后》《猎金·游戏》《熊孩子·探险熊兵》《苍茫的天涯是我的爱》《独一无二》《开心超人之逆世营救》。",
          time: "2025-03-20 03:40:00",
        },
        {
          content:
            "【美军对也门多地发动空袭】3月20日讯，据也门胡塞武装控制的迈希拉电视台当地时间20日凌晨消息，美军当天对胡塞武装控制的荷台达省宰比德地区、萨达省萨夫拉地区发动多次空袭。",
          time: "2025-03-20 03:25:00",
        },
        {
          content:
            "【土耳其政局动荡推动加密货币需求上升】比特币触及87000美元/枚，创近两周新高。此前，以太坊一度大涨超7%。有分析称，加密货币市场走强的导火索或许是土耳其政局动荡，在币安平台上比特币-土耳其里拉（BTC/TRY）交易量激增，由于里拉的大幅贬值，投资者可能转向USDT等与美元挂钩的资产。数据显示，过去24小时加密货币市场有超10万人被爆仓。",
          time: "2025-03-20 03:10:00",
        },
        {
          content:
            "韩国KOSPI指数3月20日（周四）开盘上涨19.67点，涨幅0.75%，报2648.29点。",
          time: "2025-03-20 02:55:00",
        },
      ],
      logo: [
        { pic: require("@/assets/home/logo1.png") },
        { pic: require("@/assets/home/logo2.png") },
        { pic: require("@/assets/home/logo3.png") },
      ],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      let params = {
        search: this.searchText,
        type: this.tabs[this.tabCur].type,
      };
      this.$api.requestGet("/api/symbols", params).then((res) => {
        console.log(res.data);
        this.currencyList = res.data;
      });
    },
    tabchange(id) {
      this.tabCur = id;
      this.initData();
    },
    toLogin() {
      window.location.href = window.global.webLink;
    },
    search(val) {
      this.searchText = val;
      this.initData();
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .topic1 {
    width: 1200px;
    margin: 0 auto;
    height: 590px;
    background: url("~@/assets/home/name-e3ff42f5.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    display: flex;
    &-left {
      width: 560px;
      h2 {
        margin-top: 100px;
        font-size: 40px;
        font-weight: 700;
        span {
          color: #ed7038;
        }
      }
      p {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        span {
          cursor: pointer;
          color: #5d3cef;
        }
      }
      .btn {
        margin-top: 35px;
        min-width: 136px;
        min-height: 52px;
        background: #5d3cef;
        border-radius: 4px;
        color: #fff;
        padding: 5px;
        cursor: pointer;
        text-transform: none;
        border: unset;
        font-size: 16px;
      }
    }
    &-right {
      position: relative;
      .img {
        position: absolute;
        bottom: 0;
        left: 50px;
      }
    }
  }
  .topic2 {
    width: 1200px;
    margin: 120px auto;
    display: flex;
    &-left {
      padding: 25px 30px;
      background: #fff;
      box-shadow: 0 0 22px 8px rgba(0, 0, 0, 0.03);
      margin-right: 100px;
      width: 635px;
      .tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .tab {
          width: 92px;
          height: 36px;
          background: #f6f6f7;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 500;
          padding: 5px;
        }
        .active {
          background: #5d3cef;
          color: #fff;
        }
      }
      /deep/.el-input {
        // 输入框高度
        height: 48px;
        // 边框圆角
        border-radius: 4px;
        margin-bottom: 20px;
      }
      /deep/.el-input__inner {
        height: 48px;
        background: #f6f6f7;
        color: #606266;
        font-size: 16px;
      }
      .titles {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dcdfe6;
        font-size: 14px;
        font-weight: 400;
        .title1 {
          width: 120px;
          padding: 20px;
          box-sizing: border-box;
          div {
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
        .title2 {
          width: 110px;
          padding: 20px;
          box-sizing: border-box;

          div {
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
        .title3 {
          width: 145px;
          padding: 20px;
          box-sizing: border-box;
          div {
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
        .title4 {
          width: 95px;
          padding: 20px;
          box-sizing: border-box;
          div {
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }
      }
      .currency {
        padding: 8px 0;
        box-sizing: border-box;
        display: flex;
        align-content: center;
        border-bottom: 1px solid #dcdfe6;
        font-size: 14px;
        font-weight: 400;
        .name {
          width: 120px;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .currency-logo {
            width: 26px;
            height: 26px;
            margin-right: 5px;
          }
        }
        .item1 {
          padding: 20px;
          box-sizing: border-box;
          width: 110px;
        }
        .item2 {
          width: 145px;
          padding: 20px;
          box-sizing: border-box;
        }
        .item3 {
          width: 95px;
          padding: 20px;
          box-sizing: border-box;
        }
        .item4 {
          margin-left: auto;
          display: flex;
          align-items: center;
          .btn {
            cursor: pointer;
            min-width: 84px;
            min-height: 36px;
            padding: 8px 15px;
            box-sizing: border-box;
            background: #f6f6f7;
            color: #5d3cef;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            border: transparent;
          }
        }
      }
    }
    &-right {
      .title {
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 44px;
      }
      .item {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
        .right {
          height: 12px;
          width: 14px;
          margin-right: 20px;
        }
      }

      .b {
        margin-top: 30px;
        display: flex;
        .btn {
          padding: 0 10px;
          cursor: pointer;
          text-align: center;
          line-height: 52px;
          min-width: 152px;
          min-height: 52px;
          border-radius: 4px;
          background: #5d3cef;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
  .topic3 {
    background-color: #212529;
    padding: 120px 0;
    color: #fff;

    .title {
      font-size: 44px;
      margin-bottom: 16px;
      font-weight: 600;
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 40px;
      font-size: 16px;
    }
    .b {
      text-align: center;
      margin-bottom: 55px;
      .btn {
        padding: 0 10px;
        cursor: pointer;
        text-align: center;
        display: inline-block;
        min-width: 220px;
        min-height: 52px;
        line-height: 52px;
        border-radius: 4px;
        background: #5d3cef;
        color: #fff;
      }
    }
    .down {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      &-left {
        width: 590px;
        margin-right: 20px;
        &-up {
          background: linear-gradient(0deg, #292d37, #292d37),
            linear-gradient(0deg, #444c5a, #444c5a);
          border: 1px solid #444c5a;
          border-radius: 4px;
          height: 112px;
          align-items: center;
          padding: 13px 16px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          &-img {
            height: 85px;
            width: 85px;
            margin-right: 20px;
          }
          .tent {
            .title1 {
              font-size: 24px;
              margin-bottom: 9px;
            }
            .type {
              color: rgba(255, 255, 255, 0.66);
            }
          }
        }
        &-down {
          background: linear-gradient(0deg, #292d37, #292d37),
            linear-gradient(0deg, #444c5a, #444c5a);
          border: 1px solid #444c5a;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          .title2 {
            width: 100%;
            display: flex;
            align-items: center;
            .t1 {
              font-size: 24px;
            }
            .t2 {
              text-align: center;
              background: #444852;
              min-width: 105px;
              min-height: 37px;
              line-height: 37px;
              border-radius: 2px;
              color: #fff;
              font-size: 14px;
              font-weight: 600;
              cursor: pointer;
              margin: 0 10px 0 auto;
            }
            .t3 {
              text-align: center;
              background: #444852;
              min-width: 105px;
              min-height: 37px;
              line-height: 37px;
              border-radius: 2px;
              color: #fff;
              font-size: 14px;
              font-weight: 600;
              cursor: pointer;
            }
          }
          .img {
            &-pic {
              width: 466px;
              height: 319px;
            }
          }
        }
      }
      &-right {
        flex: 1;
        background: linear-gradient(0deg, #292d37, #292d37),
          linear-gradient(0deg, #444c5a, #444c5a);
        border: 1px solid #444c5a;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        padding-bottom: 0;

        .title2 {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 29px;
          .t1 {
            font-size: 24px;
          }
          .t2 {
            text-align: center;
            background: #444852;
            min-width: 105px;
            min-height: 37px;
            line-height: 37px;
            border-radius: 2px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
            margin: 0 10px 0 auto;
          }
          .t3 {
            text-align: center;
            background: #444852;
            min-width: 105px;
            min-height: 37px;
            line-height: 37px;
            border-radius: 2px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          }
        }
        .img {
          width: 525px;
          height: 449px;
          &-pic {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  .topic4 {
    padding: 120px 0;
    .title3 {
      text-align: center;
      font-size: 44px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    p {
      text-align: center;
      font-size: 16px;
      margin-bottom: 55px;
    }
    .boxs {
      width: 1200px;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      .box {
        width: 584px;
        min-height: 304px;
        box-shadow: 0 0 55px rgba(0, 0, 0, 0.06);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        margin-bottom: 56px;
        margin-right: 32px;
        &-img {
          width: 102px;
          height: 102px;
          margin-bottom: 16px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        &-title {
          font-size: 28px;
          margin-bottom: 12px;
        }
        &-desc {
          text-align: center;
        }
      }
      .box:nth-child(2n) {
        margin-right: 0;
      }
    }
    .title4 {
      font-size: 44px;
      text-align: center;
    }
    .boxs2 {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 80px auto 0;
      &-box {
        width: 393px;
        min-height: 241px;
        border: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 45px 25px;
        margin-right: 10px;
        &-img {
          width: 58px;
          height: 58px;
          margin-bottom: 10px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        &-title {
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 8px;
        }
        &-desc {
          text-align: center;
        }
      }
    }
  }
  .topic5 {
    background: linear-gradient(180deg, #f4f7fa 0%, #fefefe 100%);
    padding: 170px;

    &-box {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      &-left {
        margin-top: 75px;
        &-title {
          margin-bottom: 45px;
        }
        /deep/ .el-step__title {
          color: #000;
          font-size: 20px;
        }
        /deep/.el-step__description {
          font-size: 16px;
          color: rgba(60, 63, 67, 0.63);
        }
        &-b {
          margin-top: 30px;
          &-btn {
            cursor: pointer;
            display: inline-block;
            text-align: center;
            line-height: 56px;
            min-width: 188px;
            min-height: 56px;
            border-radius: 4px;
            background: #5d3cef;
            color: #fff;
          }
        }
      }
      &-right {
        &-img {
          width: 752px;
          height: 560px;
        }
      }
    }
  }
  .topic6 {
    background: #212529;
    padding: 130px 0 190px;
    color: #fff;
    &-title {
      font-size: 44px;
      text-align: center;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      text-align: center;
    }
    &-down {
      margin: 120px auto 0;
      display: flex;
      width: 1200px;
      &-left {
        min-height: 408px;
        width: 486px;
        background: url("~@/assets/home/name-3b4ecd71.png") no-repeat;
        background-size: cover;
        padding: 42px 30px;
        &-title {
          font-size: 36px;
          margin-bottom: 5px;
        }
        &-desc {
          font-size: 20px;
        }
      }
      &-right {
        min-height: 408px;
        width: 714px;
        padding: 45px 60px;
        background: #2a313e;
        position: relative;
        left: 0;
        top: 0;
        &-item {
          margin-bottom: 25px;
          width: 280px;
          &-name {
            font-size: 16px;
            margin-bottom: 2px;
          }
          &-desc {
            font-size: 12px;
            color: rgb(153, 153, 153);
          }
        }
        &-img {
          position: absolute;
          right: -19px;
          top: -35px;
          width: 398px;
          height: 428px;
        }
      }
    }
  }
  .topic7 {
    padding: 120px 0;
    &-title {
      margin-bottom: 80px;
      text-align: center;
      font-size: 44px;
    }
    &-down {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;
      &-left {
        width: 584px;
        background: #f6f6f7;
        padding: 30px 42px;
        margin-right: 32px;
        &-title {
          font-size: 32px;
          margin-bottom: 20px;
        }
        .news {
          display: flex;
          flex-direction: column;
          width: 500px;
          height: 140px;
          background: #fff;
          box-shadow: 0 0 23px rgba(0, 0, 0, 0.04);
          padding: 25px 32px;
          margin-bottom: 20px;
          &-content {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            margin-bottom: 20px;
            font-size: 18px;
          }
          &-time {
            font-size: 14px;
            color: rgb(153, 153, 153);
          }
        }
        .news:last-child {
          margin: 0;
        }
      }
      &-right {
        width: 584px;
        height: 748px;
        &-img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .topic8 {
    padding: 0 0 190px;
    width: 1200px;
    margin: 0 auto;
    &-title {
      margin-bottom: 40px;
      font-size: 44px;
      text-align: center;
    }
    &-title1 {
      margin-bottom: 16px;
      font-size: 44px;
      text-align: center;
    }
    p {
      margin-bottom: 80px;
      text-align: center;
      font-size: 16px;
    }
    .friends {
      display: flex;
      margin-bottom: 100px;
      &-img {
        width: 373px;
        height: 114px;
        margin-right: 41px;
      }
    }
  }
  .topic9 {
    width: 100%;
    background: #212529;
    padding: 94px 0 73px;
    &-title {
      text-align: center;
      color: #fff;
      font-size: 44px;
      margin-bottom: 40px;
    }
    &-b {
      text-align: center;
      &-btn {
        padding: 0 10px;
        cursor: pointer;
        text-align: center;
        line-height: 56px;
        display: inline-block;
        min-width: 188px;
        min-height: 56px;
        border-radius: 4px;
        background: #5d3cef;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}
</style>
