<template>
  <div class="content">
    <bannerVue
      bgcolor="#f2f4f6"
      titlecolor="#141e30"
      desccolor="#141e30"
      :title="$t('教育')"
      :desc="
        $t(
          '我们提供丰富的英文版在线资源、交易指南，以及专家网络研讨会。 如果您想了解更多信息或正在寻找某些具体的信息，请与我们联系'
        )
      "
      :picUrl="pic1"
      pic-height="336px"
      pic-width="626px"
    ></bannerVue>
    <div class="topic1">
      <div class="topic1-box">
        <h5 class="topic1-box-h5">{{ $t("首页") }}/ {{ $t("教育") }}</h5>
      </div>
    </div>
    <div class="topic2">
      <div class="topic2-box">
        <h2 class="topic2-box-h2">{{ $t("学习交易外汇") }}</h2>
        <h5 class="topic2-box-h5">
          {{ $t("交易新手？您可以在这里找到开始旅程所需的所有交易基础知识。") }}
        </h5>
        <div class="topic2-box-tips">
          <div
            class="topic2-box-tips-item"
            v-for="(item, index) in tips"
            :key="index"
          >
            <img class="topic2-box-tips-item-img" :src="item.pic" alt="" />
            <div class="topic2-box-tips-item-bottom">
              <span class="topic2-box-tips-item-bottom-apan">{{
                $t(item.tag)
              }}</span>
              <h5 class="topic2-box-tips-item-bottom=h5">
                {{ $t(item.title) }}
              </h5>
              <h6 class="topic2-box-tips-item-bottom-h6">
                {{ $t(item.desc) }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="topic4">
      <h2 class="topic4-h2">{{ $t("做好交易准备了吗？") }}</h2>
      <h5 class="topic4-h5">
        {{
          $t(
            "上手简单快捷——即使是小额存款。通过我们简单的申请流程在几分钟内完成申请。"
          )
        }}
      </h5>
      <div class="topic4-btn">{{ $t("开立真实用户") }}</div>
    </div>
  </div>
</template>

<script>
import bannerVue from "@/components/banner.vue";

export default {
  components: {
    bannerVue,
  },
  data() {
    return {
      pic1: require("@/assets/education/pic1.png"),
      tips: [
        {
          pic: require("@/assets/education/pic2.png"),
          tag: "Beginner",
          title: "外汇交易如何操作？",
          desc: "外汇交易是购买一种货币的同时卖出另一种货币的同步行为。...",
        },
        {
          pic: require("@/assets/education/pic3.png"),
          tag: "Beginner",
          title: "您需要了解的10个交易术语",
          desc: "如果您刚刚开始交易并在阅读市场上的所有内容，那么您可能会遇到大量的行话和术语。...",
        },
        {
          pic: require("@/assets/education/pic4.png"),
          tag: "Beginner",
          title: "技术分析",
          desc: "技术和价格行为分析是两个非常受欢迎的交易研究领域，都涉及使用图表来理解市场情绪、心理和行为。但是，二者有着显著的差异。...",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  .topic1 {
    width: 100%;
    display: flex;
    justify-content: center;
    &-box {
      width: 1200px;
      padding-left: 2rem;
      padding-right: 2rem;
      &-h5 {
        font-size: 12px;
        margin-top: 1rem;
        margin-bottom: 2rem;
        color: #141e30;
        line-height: 180%;
      }
    }
  }
  .topic2 {
    width: 100%;
    display: flex;
    justify-content: center;
    &-box {
      width: 1200px;
      text-align: center;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 20px;
      &-h2 {
        margin-top: 2rem;
        font-weight: 600;
        font-size: 40px;
        margin-bottom: 12px;
        color: #141e30;
      }
      &-h5 {
        margin-bottom: 2rem;
        color: #141e30;
        font-size: 18px;
        line-height: 180%;
        font-weight: 300;
      }
      &-tips {
        column-gap: 0.75rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        display: grid;
        &-item {
          box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.05);
          &-img {
            width: 370px;
            height: 200px;
          }
          &-bottom {
            text-align: left;
            padding: 2rem;
            &-span {
              background: #9bdfce;
              border-radius: 2px;
              padding: 4px 12px;
              display: inline-block;
              margin-bottom: 24px;
            }
            &-h5 {
              font-weight: 400;
              font-size: 24px;
              margin-bottom: 1rem;
              line-height: 180%;
              color: #141e30;
            }
            &-h6 {
              color: #141e30;
              font-size: 16px;
              line-height: 160%;
              font-weight: 300;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
  .topic4 {
    background: url("~@/assets/why/bg.png") no-repeat center;
    background-size: auto 100%;
    height: 394px;
    width: 100%;
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-h2 {
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 12px;
    }
    &-h5 {
      font-size: 18px;
      line-height: 180%;
      font-weight: 300;
    }
    &-btn {
      background: #2465f1;
      border: unset;
      border-radius: 64px;
      color: #fff;
      cursor: pointer;
      padding: 0 40px;
      height: 46px;
      line-height: 46px;
      box-sizing: border-box;
      margin: 1rem 0;
      span {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
</style>
