<template>
  <div style="width: 100vw; height: 100vh">
    <iframe
      :src="url"
      style="width: 100%; height: 100%"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    this.url = this.$route.query.url;
  },
};
</script>

<style></style>
