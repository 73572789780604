export const LANGUAGE_TYPE = [
  { label: '简体中文', value: 'zh-cn' },
  { label: '繁體中文', value: 'zh-hk' },
  { label: 'English', value: 'en' },
  { label: '日本', value: 'ja-jp' },
  { label: 'عربي', value: 'ar' },
  { label: 'Français', value: 'fr' },
  { label: 'Deutsch', value: 'de' },
  { label: 'español', value: 'es' },
  { label: 'Italiano', value: 'it' },
  { label: 'हिन्दी', value: 'hi' },
  { label: 'Indonesia', value: 'id' },
  { label: 'ไทย', value: 'th' },
  // { label: 'Türk', value: 'tr' },
  { label: '한국인', value: 'ko' },
  { label: 'Tiếng Việt', value: 'vi' },
  { label: 'Русский', value: 'ru' },
  { label: 'Português', value: 'pt' },
]


export const PARAMETER = {
  class: 'index',
  action: 'joinRoom',
  room: '',
  access_token: ''
}
export const LOGIN = {
  class: 'index',
  action: 'login',
  room: '',
  access_token: ''
}
