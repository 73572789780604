<template>
  <div class="content">
    <bannerVue
      bgcolor="#f2f4f6"
      titlecolor="#141e30"
      desccolor="#141e30"
      :title="$t('联系我们')"
      title-width="1200px"
      desc-width="1200px"
      :desc="$t('无论您身在何处，都有很多方法可以与我们取得联系')"
      picUrl=""
      top="3rem"
      bottom="3rem"
    ></bannerVue>
    <div class="topic1">
      <div class="topic1-box">
        <div class="topic1-box-item">
          <h2 class="topic1-box-item-h2">24/7</h2>
          <div class="topic1-box-item-bottom">
            <h5 class="topic1-box-item-bottom-h5">Support</h5>
            <h5 class="topic1-box-item-bottom-h5 blue">
              MarketWeapp@gamil.com
            </h5>
          </div>
        </div>
        <div class="topic1-box-item">
          <h2 class="topic1-box-item-h2">{{ $t("其他") }}</h2>
          <div class="topic1-box-item-bottom">
            <h5 class="topic1-box-item-bottom-h5">{{ $t("媒体") }}</h5>
            <h5 class="topic1-box-item-bottom-h5 blue">
              MarketWeapp@gamil.com
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerVue from "@/components/banner.vue";

export default {
  components: {
    bannerVue,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content {
  .topic1 {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    &-box {
      padding-left: 2rem;
      padding-right: 2rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      width: 1200px;
      display: grid;
      &-item {
        &-h2 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 12px;
          color: #141e30;
        }
        &-bottom {
          justify-content: space-between;
          padding-right: 7rem;
          display: flex;
          &-h5 {
            color: #141e30;
            font-size: 18px;
            line-height: 180%;
            font-weight: 300;
          }
        }
      }
    }
  }
  .blue {
    font-size: 18px;
    color: #2038d3;
    font-weight: 300;
    text-decoration: underline;
  }
}
</style>
